var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.items,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([
      {
        key: "item.paid",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("span", [
              _vm._v(
                _vm._s(
                  item.status === "Paid"
                    ? _vm.$dollarFormat(item.amount)
                    : "$0.00"
                )
              )
            ])
          ]
        }
      },
      {
        key: "item.amount",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.$dollarFormat(item.amount)))
            ])
          ]
        }
      },
      {
        key: "item.status",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-chip",
              {
                staticClass: "primary--text text--darken-2 text-uppercase",
                attrs: { color: "success", small: "" }
              },
              [_vm._v(_vm._s(item.status))]
            )
          ]
        }
      },
      {
        key: "item.item",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("div", { staticClass: "py-1" }, [
              _vm._v(
                " " +
                  _vm._s(item.applicant) +
                  " | " +
                  _vm._s(item.unit) +
                  " | " +
                  _vm._s(item.requester.name) +
                  " ( " +
                  _vm._s(item.requester.role) +
                  " ) "
              )
            ]),
            _vm._l(item.products, function(product, index) {
              return _c(
                "div",
                { key: index, staticClass: "py-1" },
                [
                  _c(
                    "span",
                    { staticStyle: { width: "260px" } },
                    [
                      _c("v-icon", { staticClass: "mr-1 primary--text" }, [
                        _vm._v("mdi-tag")
                      ]),
                      _vm._v(" " + _vm._s(product.name) + " "),
                      product.addon
                        ? _c(
                            "v-chip",
                            {
                              staticClass: "mr-2",
                              attrs: { "x-small": "", color: "secondary" }
                            },
                            [_vm._v("Add-On")]
                          )
                        : _vm._e(),
                      product.instant
                        ? _c(
                            "v-chip",
                            { staticClass: "mr-2", attrs: { "x-small": "" } },
                            [_vm._v("Instant")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-chip",
                    { attrs: { "x-small": "", color: "secondary" } },
                    [_vm._v(_vm._s(product.price))]
                  )
                ],
                1
              )
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }