<template>
    <v-data-table :headers="headers" :items="items" hide-default-footer>
        <template v-slot:item.paid="{ item }">
            <span>{{
                item.status === "Paid" ? $dollarFormat(item.amount) : "$0.00"
            }}</span>
        </template>
        <template v-slot:item.amount="{ item }">
            <span class="font-weight-bold">{{
                $dollarFormat(item.amount)
            }}</span>
        </template>
        <template v-slot:item.status="{ item }">
            <v-chip
                color="success"
                small
                class="primary--text text--darken-2 text-uppercase"
                >{{ item.status }}</v-chip
            >
        </template>
        <template v-slot:item.item="{ item }">
            <div class="py-1">
                {{ item.applicant }} | {{ item.unit }} |
                {{ item.requester.name }} ( {{ item.requester.role }} )
            </div>
            <div
                v-for="(product, index) in item.products"
                :key="index"
                class="py-1"
            >
                <span style="width: 260px">
                    <v-icon class="mr-1 primary--text">mdi-tag</v-icon>
                    {{ product.name }}
                    <v-chip
                        class="mr-2"
                        x-small
                        color="secondary"
                        v-if="product.addon"
                        >Add-On</v-chip
                    >
                    <v-chip class="mr-2" x-small v-if="product.instant"
                        >Instant</v-chip
                    >
                </span>

                <v-chip x-small color="secondary">{{ product.price }}</v-chip>
            </div>
        </template>
    </v-data-table>
</template>
<script>
export default {
    name: "invoice-items",
    components: {},
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                {
                    text: "Order #",
                    align: "start",
                    value: "order_id",
                },
                {
                    text: "Status",
                    align: "start",
                    value: "status",
                },
                {
                    text: "Item (Applicant | Unit | Requester | Products )",
                    align: "start",
                    value: "item",
                },
                {
                    text: "Amount",
                    align: "end",
                    value: "amount",
                },
                {
                    text: "Paid",
                    align: "end",
                    value: "paid",
                },
            ],
        };
    },
};
</script>

<style scoped>
</style>